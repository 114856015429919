import { render, staticRenderFns } from "./Modalcatcode.vue?vue&type=template&id=867a5370&scoped=true&"
import script from "./Modalcatcode.vue?vue&type=script&lang=js&"
export * from "./Modalcatcode.vue?vue&type=script&lang=js&"
import style0 from "./Modalcatcode.vue?vue&type=style&index=0&id=867a5370&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "867a5370",
  null
  
)

export default component.exports